<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"/>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."/>
            <header-table :re-fetch-data="reFetchData"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="No record found"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'partner-edit'"></id-column-table>
      </template>

      <template #cell(profile_picture_url)="data">
        <b-avatar
            :src="getApiFile(data.value)"
            :text="avatarText(data.item.first_name + ' ' + data.item.last_name)"
            :variant="`light-primary`"
            size="30px"/>
      </template>

      <template #cell(status)="data">
        <b-badge pill :variant="`light-secondary`" class="text-capitalize" v-if="data.value === 1">
          InActive
        </b-badge>
        <b-badge pill :variant="`light-success`" class="text-capitalize" v-if="data.value === 2">
          Approve
        </b-badge>
        <b-badge pill :variant="`light-danger`" class="text-capitalize" v-if="data.value === 3">
          Decline
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item @click="approve(data.item)">
              <feather-icon icon="CheckCircleIcon"/>
              <span class="align-middle ml-50">Approve</span>
            </b-dropdown-item>
            <b-dropdown-item @click="decline(data.item)">
              <feather-icon icon="XCircleIcon"/>
              <span class="align-middle ml-50">Decline</span>
            </b-dropdown-item>
            <b-dropdown-item @click="websiteLogin(data.item)">
              <feather-icon icon="LockIcon"/>
              <span class="align-middle ml-50">Login as This User</span>
            </b-dropdown-item>
            <b-dropdown-item @click="viewServiceAgreement(data.item)">
              <feather-icon icon="EyeIcon"/>
              <span class="align-middle ml-50">View Service Agreement</span>
            </b-dropdown-item>            
            <b-dropdown-item @click="remove(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Remove</span>
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="$router.push({ name: 'partner-edit', params: { id: data.item.id }})" class="mx-1"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>

    <form :action="websiteUrl" method="post" id="record-form">
      <input type="hidden" name="panel-key" id="record-key" :value="panelKey"/>
      <input type="hidden" name="email" id="record-email" :value="recordEmail"/>
      <input type="hidden" name="token" id="record-token" :value="recordToken"/>
    </form>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
  ActiveColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import {onUnmounted, ref} from '@vue/composition-api'
import useList from './useList'
import {avatarText, getApiFile} from "@core/utils/filter";
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from './store'
import Overlay from "@/components/Overlay.vue";
import Vue from "vue";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
    DateColumnTable, ActiveColumnTable, IdColumnTable,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
    } = useList()

    const approve = (dataItem) => {
      Vue.swal({
        title: 'Approve?',
        text: 'Record will be approved!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/approveItem', {id: dataItem.id}).then(response => {
            reFetchData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const decline = (dataItem) => {
      Vue.swal({
        title: 'Decline?',
        text: 'Record will be declined!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Decline',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/declineItem', {id: dataItem.id}).then(response => {
            reFetchData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const websiteUrl = $themeConfig.app.appWebSiteUrl + '/partner/panel-login';
    const panelKey = ref('')
    const recordEmail = ref('')
    const recordToken = ref('')
    const websiteLogin = (dataItem) => {
      document.getElementById("record-key").value = $themeConfig.app.appPanelKey;
      document.getElementById("record-email").value = dataItem.email;
      document.getElementById("record-token").value = dataItem.referral_code;
      document.getElementById("record-form").submit();
    }

    const remove = (dataItem) => {
      Vue.swal({
        title: 'Remove?',
        text: 'Record will be removed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeItem', {id: dataItem.id}).then(response => {
            reFetchData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const viewServiceAgreement = (dataItem) => {
      store.dispatch('store/serviceAgreement', {id: dataItem.id}).then(response => {

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);

      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
    }    

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      panelKey,
      recordEmail,
      recordToken,
      websiteUrl,

      fetchList,
      reFetchData,
      avatarText,
      getApiFile,
      approve,
      decline,
      websiteLogin,
      remove,
      viewServiceAgreement
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
